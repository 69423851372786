import { TextField } from "@mui/material";

export default function DataTextField({ required, errorMsg, label, setValue }) {
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const stylez = {
    "& .MuiInputBase-root": {
      height: "40px", // Adjust height here
      width: "170px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px 10px", // Adjust padding inside the input
    },
    marginTop: 2,
  };

  return (
    <div>
      {/*text fields have to be surrounded by div otherwise there is a UI bug */}
      <TextField
        helperText={errorMsg === null ? "" : errorMsg}
        onChange={handleInputChange}
        required={required}
        size="small"
        label={label}
        variant="outlined"
        sx={stylez}
      />
    </div>
  );
}
