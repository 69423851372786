import picksData from "../WE/picksData.json";
import {
  TextField,
  Box,
  CircularProgress,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  AlertTitle,
  Alert,
} from "@mui/material";
import { JSX, useState } from "react";
import DataTextField from "./DataTextField";
import { Week, Pick } from "../../firebase/models";
import { FillerPickData } from "./FillerPickData";
import MTC_logo from "../../imgs/MTCLogo/MTC_logo.png";
import { initializeApp } from "firebase/app";
// import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDbL2YiSVlHJZr03t7M_xXMZXKxY6hw2bQ",
  authDomain: "mtc-db-firebase.firebaseapp.com",
  projectId: "mtc-db-firebase",
  storageBucket: "mtc-db-firebase.firebasestorage.app",
  messagingSenderId: "340944976821",
  appId: "1:340944976821:web:1d6df1b2a1022ee44c77fc",
  measurementId: "G-4YT5V8LPYS",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// const addPickToQuarter = async (
//   pickId: string | number,
//   pickType: string | number,
//   songOrAlbumName: string,
//   artistName: string,
//   memberName: string,
//   songOrAlbumArt: string
// ) => {
//   const docRef = doc(db, "picksData", "picksDataDocID");
//   const newPick = {
//     pickId: pickId,
//     pickType: pickType,
//     songOrAlbumName: songOrAlbumName,
//     artistName: artistName,
//     memberName: memberName,
//     songOrAlbumArt: songOrAlbumArt,
//   };
//   try {
//     await updateDoc(docRef, {
//       "quarters.0.weeks.0.picks": arrayUnion(newPick),
//     });
//     console.log("Pick added successfully! :)");
//   } catch (error) {
//     console.log("Error adding new pick: ", error);
//   }
// };

// const addPickToQuarter = async (
//   pickId: string | number,
//   pickType: string | number,
//   songOrAlbumName: string,
//   artistName: string,
//   memberName: string,
//   songOrAlbumArt: string
// ) => {
//   const docRef = doc(db, "picksData", "picksDataDocID");

//   const newPick = {
//     pickId,
//     pickType,
//     songOrAlbumName,
//     artistName,
//     memberName,
//     songOrAlbumArt,
//   };

//   try {
//     const docSnap = await getDoc(docRef);
//     if (docSnap.exists()) {
//       const data = docSnap.data();

//       // Check if the full path exists
//       const picksPath = data?.quarters?.[0]?.weeks?.[0]?.picks;

//       if (Array.isArray(picksPath)) {
//         // Path exists, use arrayUnion
//         await updateDoc(docRef, {
//           "quarters.0.weeks.0.picks": arrayUnion(newPick),
//         });
//       } else {
//         // Path doesn't exist, need to set it with the new array
//         // Firestore doesn't support nested field creation automatically,
//         // so you might need to reconstruct the full path
//         const updatedData = {
//           quarters: [
//             {
//               weeks: [
//                 {
//                   picks: [newPick],
//                 },
//               ],
//             },
//           ],
//         };
//         await setDoc(docRef, updatedData, { merge: true });
//       }

//       console.log("Pick added successfully! :)");
//     } else {
//       console.error("Document does not exist.");
//     }
//   } catch (error) {
//     console.log("Error adding new pick: ", error);
//   }
// };

// const addPickToQuarter = async (
//   pickId: string | number,
//   pickType: string | number,
//   songOrAlbumName: string,
//   artistName: string,
//   memberName: string,
//   songOrAlbumArt: string,
//   quarterSelect: string,
//   weekSelect: string
// ) => {
//   const docRef = doc(db, "picksData", "picksDataDocID");

//   const quarterSelectOptions = ["WQ24", "SQ24", "FQ24", "WQ25", "SQ25"];
//   const weekSelectOptions = [
//     "WEEK 1",
//     "WEEK 2",
//     "WEEK 3",
//     "WEEK 4",
//     "WEEK 5",
//     "WEEK 6",
//     "WEEK 7",
//     "WEEK 8",
//     "WEEK 9",
//     "WEEK 10",
//     "WEEK 11",
//   ];

//   const quarterIndex = quarterSelectOptions.indexOf(quarterSelect);
//   const weekIndex = weekSelectOptions.indexOf(weekSelect);

//   if (quarterIndex === -1 || weekIndex === -1) {
//     console.error("Invalid quarter or week selected");
//     return;
//   }

//   const newPick = {
//     pickId,
//     pickType,
//     songOrAlbumName,
//     artistName,
//     memberName,
//     songOrAlbumArt,
//   };

//   try {
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//       const data = docSnap.data();
//       const picksPath =
//         data?.quarters?.[quarterIndex]?.weeks?.[weekIndex]?.picks;

//       const firestorePath = `quarters.${quarterIndex}.weeks.${weekIndex}.picks`;

//       if (Array.isArray(picksPath)) {
//         // Path exists, use arrayUnion
//         await updateDoc(docRef, {
//           [firestorePath]: arrayUnion(newPick),
//         });
//       } else {
//         // Path doesn't exist, need to construct the nested structure
//         const partialUpdate: any = {
//           quarters: [],
//         };

//         // Ensure quarters array exists up to the required index
//         partialUpdate.quarters[quarterIndex] = {
//           weeks: [],
//         };

//         // Ensure weeks array exists up to the required index
//         partialUpdate.quarters[quarterIndex].weeks[weekIndex] = {
//           picks: [newPick],
//         };

//         await setDoc(docRef, partialUpdate, { merge: true });
//       }

//       console.log("Pick added successfully! 🎉");
//     } else {
//       console.error("Document does not exist.");
//     }
//   } catch (error) {
//     console.log("Error adding new pick: ", error);
//   }
// };
// import { doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";

const PickDataEntry = () => {
  const [songOrAlbumNameAotW, setSongOrAlbumNameAotW] = useState("");
  const [artistNameAotW, setArtistNameAotW] = useState("");
  const [memberNameAotW, setMemberNameAotW] = useState("");
  const [songOrAlbumArtAotW, setSongOrAlbumArtAotW] = useState("");

  const [songOrAlbumNameRUAotW, setSongOrAlbumNameRUAotW] = useState("");
  const [artistNameRUAotW, setArtistNameRUAotW] = useState("");
  const [memberNameRUAotW, setMemberNameRUAotW] = useState("");
  const [songOrAlbumArtRUAotW, setSongOrAlbumArtRUAotW] = useState("");

  const [songOrAlbumNameSotW, setSongOrAlbumNameSotW] = useState("");
  const [artistNameSotW, setArtistNameSotW] = useState("");
  const [memberNameSotW, setMemberNameSotW] = useState("");
  const [songOrAlbumArtSotW, setSongOrAlbumArtSotW] = useState("");

  const [songOrAlbumNameRUSotW, setSongOrAlbumNameRUSotW] = useState("");
  const [artistNameRUSotW, setArtistNameRUSotW] = useState("");
  const [memberNameRUSotW, setMemberNameRUSotW] = useState("");
  const [songOrAlbumArtRUSotW, setSongOrAlbumArtRUSotW] = useState("");

  const [status, setStatus] = useState("");

  const AOTWINDEX = 0;
  const RUAOTWINDEX = 1;
  const SOTWINDEX = 2;
  const RUSOTWINDEX = 3;
  const PICKIDINDEX = 0;
  const PICKTYPEINDEX = 1;

  const ifNotSpotifyLink =
    "If there's no Spotify API image link, upload art as png after clicking 'ADD' button below.";

  const addPickToQuarter = async (
    pickId: string | number,
    pickType: string | number,
    songOrAlbumName: string,
    artistName: string,
    memberName: string,
    songOrAlbumArt: string,
    quarterSelect: string,
    weekSelect: string
  ) => {
    const docRef = doc(db, "picksData", "picksDataDocID");

    const quarterSelectOptions = ["SQ25", "WQ25", "FQ24", "SQ24", "WQ24"];
    const weekSelectOptions = [
      "WEEK 1",
      "WEEK 2",
      "WEEK 3",
      "WEEK 4",
      "WEEK 5",
      "WEEK 6",
      "WEEK 7",
      "WEEK 8",
      "WEEK 9",
      "WEEK 10",
      "WEEK 11",
    ];

    const quarterIndex = quarterSelectOptions.indexOf(quarterSelect);
    const weekIndex = weekSelectOptions.indexOf(weekSelect);

    if (quarterIndex === -1 || weekIndex === -1) {
      console.error("Invalid quarter or week selected");
      return;
    }

    const newPick = {
      pickId,
      pickType,
      songOrAlbumName,
      artistName,
      memberName,
      songOrAlbumArt,
    };

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const picksPath =
          data?.quarters?.[quarterIndex]?.weeks?.[weekIndex]?.picks;
        const firestorePath = `quarters.${quarterIndex}.weeks.${weekIndex}.picks`;

        if (Array.isArray(picksPath)) {
          // If picks already exist, just push via arrayUnion
          await updateDoc(docRef, {
            [firestorePath]: arrayUnion(newPick),
          });
        } else {
          // Create a new data structure with the right nesting
          const updatedData: any = { quarters: [] };

          // Initialize the quarters array with empty objects
          for (let i = 0; i <= quarterIndex; i++) {
            updatedData.quarters[i] = { weeks: [] };
          }

          // Initialize the weeks array for the target quarter
          for (let i = 0; i <= weekIndex; i++) {
            updatedData.quarters[quarterIndex].weeks[i] = { picks: [] };
          }

          // Add the new pick to the target week
          updatedData.quarters[quarterIndex].weeks[weekIndex].picks = [newPick];

          // Merge it into Firestore
          await setDoc(docRef, updatedData, { merge: true });
        }

        console.log("✅ Pick added successfully!");
      } else {
        console.error("❌ Document does not exist.");
      }
    } catch (error) {
      console.error("🔥 Error adding new pick:", error);
    }
  };

  const handleAotWFormSubmit = async (event: any, reason?: any) => {
    event.preventDefault();
    if (reason !== "backdropClick") {
      setOpenDoubleCheckPickAotW(false);
    }
    const aotwpickid = FillerPickData[AOTWINDEX][PICKIDINDEX];
    const aotwpicktype = FillerPickData[AOTWINDEX][PICKTYPEINDEX];

    await console.log(`
    {
        pickId: ${aotwpickid}
        pickType: ${aotwpicktype}
        songOrAlbumName: ${songOrAlbumNameAotW}
        artistName: ${artistNameAotW}
        memberName: ${memberNameAotW}
        songOrAlbumArt: ${songOrAlbumArtAotW}
    }`);
    await addPickToQuarter(
      aotwpickid,
      aotwpicktype,
      songOrAlbumNameAotW,
      artistNameAotW,
      memberNameAotW,
      songOrAlbumArtAotW,
      quarterSelect,
      weekSelect
    );
  };
  const handleRUAotWFormSubmit = async (event: any, reason?: any) => {
    event.preventDefault();
    if (reason !== "backdropClick") {
      setOpenDoubleCheckPickRUAotW(false);
    }
    const ruaotwpickid = FillerPickData[RUAOTWINDEX][PICKIDINDEX];
    const ruaotwpicktype = FillerPickData[RUAOTWINDEX][PICKTYPEINDEX];

    await console.log(`{
        pickId: ${ruaotwpickid}
        pickType: ${ruaotwpicktype}
        songOrAlbumName: ${songOrAlbumNameRUAotW}
        artistName: ${artistNameRUAotW}
        memberName: ${memberNameRUAotW}
        songOrAlbumArt: ${songOrAlbumArtRUAotW}
    }`);
    await addPickToQuarter(
      ruaotwpickid,
      ruaotwpicktype,
      songOrAlbumNameRUAotW,
      artistNameRUAotW,
      memberNameRUAotW,
      songOrAlbumArtRUAotW,
      quarterSelect,
      weekSelect
    );
  };
  const handleSotWFormSubmit = async (event: any, reason?: any) => {
    event.preventDefault();
    if (reason !== "backdropClick") {
      setOpenDoubleCheckPickSotW(false);
    }
    const sotwpickid = FillerPickData[SOTWINDEX][PICKIDINDEX];
    const sotwpicktype = FillerPickData[SOTWINDEX][PICKTYPEINDEX];

    await console.log(`{
        pickId: ${sotwpickid}
        pickType: ${sotwpicktype}
        songOrAlbumName: ${songOrAlbumNameSotW}
        artistName: ${artistNameSotW}
        memberName: ${memberNameSotW}
        songOrAlbumArt: ${songOrAlbumArtSotW}
    }`);
    await addPickToQuarter(
      sotwpickid,
      sotwpicktype,
      songOrAlbumNameSotW,
      artistNameSotW,
      memberNameSotW,
      songOrAlbumArtSotW,
      quarterSelect,
      weekSelect
    );
  };
  const handleRUSotWFormSubmit = async (event: any, reason?: any) => {
    event.preventDefault();
    if (reason !== "backdropClick") {
      setOpenDoubleCheckPickRUSotW(false);
    }
    const rusotwpickid = FillerPickData[RUSOTWINDEX][PICKIDINDEX];
    const rusotwpicktype = FillerPickData[RUSOTWINDEX][PICKTYPEINDEX];
    await console.log(`{
        pickId: ${rusotwpickid}
        pickType: ${rusotwpicktype}
        songOrAlbumName: ${songOrAlbumNameRUSotW}
        artistName: ${artistNameRUSotW}
        memberName: ${memberNameRUSotW}
        songOrAlbumArt: ${songOrAlbumArtRUSotW}
    }`);
    await addPickToQuarter(
      rusotwpickid,
      rusotwpicktype,
      songOrAlbumNameSotW,
      artistNameSotW,
      memberNameSotW,
      songOrAlbumArtSotW,
      quarterSelect,
      weekSelect
    );
  };

  const [quarterSelect, setQuarterSelect] = useState("???");
  const handleQuarterSelectChange = (event: any) => {
    setQuarterSelect(event.target.value);
  };
  const [weekSelect, setWeekSelect] = useState("???");
  const handleWeekSelectChange = (event: any) => {
    setWeekSelect(event.target.value);
  };
  const quarterSelectOptions = ["SQ25", "WQ25", "FQ24", "SQ24", "WQ24"];
  const weekSelectOptions = [
    "WEEK 1",
    "WEEK 2",
    "WEEK 3",
    "WEEK 4",
    "WEEK 5",
    "WEEK 6",
    "WEEK 7",
    "WEEK 8",
    "WEEK 9",
    "WEEK 10",
    "WEEK 11",
  ];

  const [openDoubleCheckPickAotW, setOpenDoubleCheckPickAotW] = useState(false);
  const [openDoubleCheckPickRUAotW, setOpenDoubleCheckPickRUAotW] =
    useState(false);
  const [openDoubleCheckPickSotW, setOpenDoubleCheckPickSotW] = useState(false);
  const [openDoubleCheckPickRUSotW, setOpenDoubleCheckPickRUSotW] =
    useState(false);

  function handleDoubleCheckPickDialogOpen(
    missingMusicName: string,
    missingArtistName: string,
    missingMemberName: string,
    pickType: string
  ) {
    if (missingMusicName && missingArtistName && missingMemberName) {
      if (pickType == "AotW") {
        setOpenDoubleCheckPickAotW(true);
      } else if (pickType == "RUAotW") {
        setOpenDoubleCheckPickRUAotW(true);
      } else if (pickType == "SotW") {
        setOpenDoubleCheckPickSotW(true);
      } else if (pickType == "RUSotW") {
        setOpenDoubleCheckPickRUSotW(true);
      }
    } else {
      alert(
        `MISSING DATA: ${!missingMusicName ? "Album or Song's Name, " : ""} ${
          !missingArtistName ? "Artist's Name, " : ""
        } ${!missingMemberName ? "Member's Name, " : ""}`
      );
    }
  }
  const handleDoubleCheckPickDialogClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      if (openDoubleCheckPickAotW) {
        setOpenDoubleCheckPickAotW(false);
      } else if (openDoubleCheckPickRUAotW) {
        setOpenDoubleCheckPickRUAotW(false);
      } else if (openDoubleCheckPickSotW) {
        setOpenDoubleCheckPickSotW(false);
      } else if (openDoubleCheckPickRUSotW) {
        setOpenDoubleCheckPickRUSotW(false);
      }
    }
  };
  const handleDoubleCheckPickDialogCloseOK = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      if (openDoubleCheckPickAotW) {
        setOpenDoubleCheckPickAotW(false);
        handleAotWFormSubmit(event, reason);
      } else if (openDoubleCheckPickRUAotW) {
        setOpenDoubleCheckPickRUAotW(false);
        handleRUAotWFormSubmit(event, reason);
      } else if (openDoubleCheckPickSotW) {
        setOpenDoubleCheckPickSotW(false);
        handleSotWFormSubmit(event, reason);
      } else if (openDoubleCheckPickRUSotW) {
        setOpenDoubleCheckPickRUSotW(false);
        handleRUSotWFormSubmit(event, reason);
      }
    }
  };

  return (
    <>
      <div className="faq2">
        <div style={{ textAlign: "center" }}>
          <h1>
            <span style={{ color: "gray" }}>ADD</span> {weekSelect}{" "}
            <span style={{ color: "gray" }}>OF PICKS (IN </span>
            {quarterSelect}
            <span style={{ color: "gray" }}>)</span>
          </h1>
          <div style={{ marginTop: 15, marginBottom: 20 }}>
            <FormControl style={{ width: 120, marginRight: 15 }}>
              <InputLabel id="demo-simple-select-label">Week</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={weekSelect}
                label="Quarter"
                onChange={handleWeekSelectChange}
              >
                {weekSelectOptions.map((week) => (
                  <MenuItem value={week}>{week}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: 100 }}>
              <InputLabel id="demo-simple-select-label">Quarter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={quarterSelect}
                label="Quarter"
                onChange={handleQuarterSelectChange}
              >
                {quarterSelectOptions.map((quarter) => (
                  <MenuItem value={quarter}>{quarter}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div
            style={{
              height: 555,
              border: "solid 1px lightgray",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "200px",
            }}
          >
            <h3
              style={{
                textAlign: "center",
                marginTop: -10.5,
                marginBottom: 29.5,
              }}
            >
              Album Of The Week
            </h3>
            <div>
              <div
                style={{
                  padding: 5,
                  alignItems: "center",
                  display: "flex",
                  //   justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Album Name"
                  setValue={setSongOrAlbumNameAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Artist Name"
                  setValue={setArtistNameAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Member Name"
                  setValue={setMemberNameAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="SpotifyAPI Art Link"
                  setValue={setSongOrAlbumArtAotW}
                />
                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                  {songOrAlbumArtAotW ? (
                    <img
                      src={songOrAlbumArtAotW}
                      style={{
                        width: 150,
                        height: 150,
                        marginLeft: 5,
                        marginRight: 5,
                        border: "1px solid lightgray",
                      }}
                    ></img>
                  ) : (
                    <h5
                      style={{
                        padding: 5,
                        width: 150,
                        height: 150,
                        justifyContent: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        border: "1px solid gray",
                        textAlign: "center",
                      }}
                    >
                      {ifNotSpotifyLink}
                    </h5>
                  )}
                </div>

                <form onSubmit={handleAotWFormSubmit}>
                  <>
                    <Button
                      onClick={() =>
                        handleDoubleCheckPickDialogOpen(
                          songOrAlbumNameAotW,
                          artistNameAotW,
                          memberNameAotW,
                          "AotW"
                        )
                      }
                      variant="outlined"
                      style={{ marginBottom: "-20px", marginTop: "-20px" }}
                    >
                      ADD AOTW
                    </Button>
                    <Dialog
                      disableEscapeKeyDown
                      open={openDoubleCheckPickAotW}
                      onClose={handleDoubleCheckPickDialogClose}
                    >
                      <DialogTitle>Confirm AotW Pick</DialogTitle>
                      <DialogContent>
                        <Box
                          component="form"
                          sx={{ flex: "column", textAlign: "center" }}
                        >
                          <h2>{songOrAlbumNameAotW}</h2>
                          <h2> {artistNameAotW}</h2>
                          <h2>{memberNameAotW}</h2>
                          <img
                            src={songOrAlbumArtAotW}
                            style={{
                              width: 150,
                              height: 150,
                            }}
                          ></img>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDoubleCheckPickDialogClose}>
                          Cancel
                        </Button>

                        <Button onClick={handleDoubleCheckPickDialogCloseOK}>
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                </form>
              </div>
            </div>
          </div>
          <div
            style={{
              height: 555,
              border: "solid 1px lightgray",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "200px",
            }}
          >
            <h3
              style={{
                textAlign: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              Runner Up Album Of The Week
            </h3>
            <div>
              <div
                style={{
                  padding: 5,
                  alignItems: "center",
                  display: "flex",
                  //   justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Album Name"
                  setValue={setSongOrAlbumNameRUAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Artist Name"
                  setValue={setArtistNameRUAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Member Name"
                  setValue={setMemberNameRUAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="SpotifyAPI Art Link"
                  setValue={setSongOrAlbumArtRUAotW}
                />
                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                  {songOrAlbumArtRUAotW ? (
                    <img
                      src={songOrAlbumArtRUAotW}
                      style={{
                        width: 150,
                        height: 150,
                        marginLeft: 5,
                        marginRight: 5,
                        border: "1px solid lightgray",
                      }}
                    ></img>
                  ) : (
                    <h5
                      style={{
                        padding: 5,
                        width: 150,
                        height: 150,
                        justifyContent: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        border: "1px solid gray",
                        textAlign: "center",
                      }}
                    >
                      {ifNotSpotifyLink}
                    </h5>
                  )}
                </div>

                <form onSubmit={handleRUAotWFormSubmit}>
                  <>
                    <Button
                      onClick={() =>
                        handleDoubleCheckPickDialogOpen(
                          songOrAlbumNameRUAotW,
                          artistNameRUAotW,
                          memberNameRUAotW,
                          "RUAotW"
                        )
                      }
                      variant="outlined"
                      style={{ marginBottom: "-20px", marginTop: "-20px" }}
                    >
                      ADD RUAOTW
                    </Button>
                    <Dialog
                      disableEscapeKeyDown
                      open={openDoubleCheckPickRUAotW}
                      onClose={handleDoubleCheckPickDialogClose}
                    >
                      <DialogTitle>Confirm RUAotW Pick</DialogTitle>
                      <DialogContent>
                        <Box
                          component="form"
                          sx={{ flex: "column", textAlign: "center" }}
                        >
                          <h2>{songOrAlbumNameRUAotW}</h2>
                          <h2> {artistNameRUAotW}</h2>
                          <h2>{memberNameRUAotW}</h2>
                          <img
                            src={songOrAlbumArtRUAotW}
                            style={{
                              width: 150,
                              height: 150,
                            }}
                          ></img>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDoubleCheckPickDialogClose}>
                          Cancel
                        </Button>
                        <Button onClick={handleDoubleCheckPickDialogCloseOK}>
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                </form>
              </div>
            </div>
          </div>
          <div
            style={{
              height: 555,
              border: "solid 1px lightgray",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "200px",
            }}
          >
            <h3
              style={{
                textAlign: "center",
                marginTop: -10.5,
                marginBottom: 29.5,
              }}
            >
              Song Of The Week
            </h3>
            <div>
              <div
                style={{
                  padding: 5,
                  alignItems: "center",
                  display: "flex",
                  //   justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Song Name"
                  setValue={setSongOrAlbumNameSotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Artist Name"
                  setValue={setArtistNameSotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Member Name"
                  setValue={setMemberNameSotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="SpotifyAPI Art Link"
                  setValue={setSongOrAlbumArtSotW}
                />
                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                  {songOrAlbumArtSotW ? (
                    <img
                      src={songOrAlbumArtSotW}
                      style={{
                        width: 150,
                        height: 150,
                        marginLeft: 5,
                        marginRight: 5,
                        border: "1px solid lightgray",
                      }}
                    ></img>
                  ) : (
                    <h5
                      style={{
                        padding: 5,
                        width: 150,
                        height: 150,
                        justifyContent: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        border: "1px solid gray",
                        textAlign: "center",
                      }}
                    >
                      {ifNotSpotifyLink}
                    </h5>
                  )}
                </div>

                <form onSubmit={handleSotWFormSubmit}>
                  <>
                    <Button
                      onClick={() =>
                        handleDoubleCheckPickDialogOpen(
                          songOrAlbumNameSotW,
                          artistNameSotW,
                          memberNameSotW,
                          "SotW"
                        )
                      }
                      variant="outlined"
                      style={{ marginBottom: "-20px", marginTop: "-20px" }}
                    >
                      ADD SOTW
                    </Button>
                    <Dialog
                      disableEscapeKeyDown
                      open={openDoubleCheckPickSotW}
                      onClose={handleDoubleCheckPickDialogClose}
                    >
                      <DialogTitle>Confirm SotW Pick</DialogTitle>
                      <DialogContent>
                        <Box
                          component="form"
                          sx={{ flex: "column", textAlign: "center" }}
                        >
                          <h2>{songOrAlbumNameSotW}</h2>
                          <h2> {artistNameSotW}</h2>
                          <h2>{memberNameSotW}</h2>
                          <img
                            src={songOrAlbumArtSotW}
                            style={{
                              width: 150,
                              height: 150,
                            }}
                          ></img>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDoubleCheckPickDialogClose}>
                          Cancel
                        </Button>
                        <Button onClick={handleDoubleCheckPickDialogCloseOK}>
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                </form>
              </div>
            </div>
          </div>
          <div
            style={{
              height: 555,
              border: "solid 1px lightgray",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "200px",
            }}
          >
            <h3
              style={{
                textAlign: "center",
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              Runner Up Song Of The Week
            </h3>
            <div>
              <div
                style={{
                  padding: 5,
                  alignItems: "center",
                  display: "flex",
                  //   justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Song Name"
                  setValue={setSongOrAlbumNameRUSotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Artist Name"
                  setValue={setArtistNameRUSotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Member Name"
                  setValue={setMemberNameRUSotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="SpotifyAPI Art Link"
                  setValue={setSongOrAlbumArtRUSotW}
                />
                <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                  {songOrAlbumArtRUSotW ? (
                    <img
                      src={songOrAlbumArtRUSotW}
                      style={{
                        width: 150,
                        height: 150,
                        marginLeft: 5,
                        marginRight: 5,
                        border: "1px solid lightgray",
                      }}
                    ></img>
                  ) : (
                    <h5
                      style={{
                        padding: 5,
                        width: 150,
                        height: 150,
                        justifyContent: "center",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        border: "1px solid gray",
                        textAlign: "center",
                      }}
                    >
                      {ifNotSpotifyLink}
                    </h5>
                  )}
                </div>

                <form onSubmit={handleRUSotWFormSubmit}>
                  <>
                    <Button
                      onClick={() =>
                        handleDoubleCheckPickDialogOpen(
                          songOrAlbumNameRUSotW,
                          artistNameRUSotW,
                          memberNameRUSotW,
                          "RUSotW"
                        )
                      }
                      variant="outlined"
                      style={{ marginBottom: "-20px", marginTop: "-20px" }}
                    >
                      ADD RUSOTW
                    </Button>
                    <Dialog
                      disableEscapeKeyDown
                      open={openDoubleCheckPickRUSotW}
                      onClose={handleDoubleCheckPickDialogClose}
                    >
                      <DialogTitle>Confirm RUSotW Pick</DialogTitle>
                      <DialogContent>
                        <Box
                          component="form"
                          sx={{ flex: "column", textAlign: "center" }}
                        >
                          <h2>{songOrAlbumNameRUSotW}</h2>
                          <h2> {artistNameRUSotW}</h2>
                          <h2>{memberNameRUSotW}</h2>
                          <img
                            src={songOrAlbumArtRUSotW}
                            style={{
                              width: 150,
                              height: 150,
                            }}
                          ></img>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDoubleCheckPickDialogClose}>
                          Cancel
                        </Button>
                        <Button onClick={handleDoubleCheckPickDialogCloseOK}>
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickDataEntry;
