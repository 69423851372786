import React, { useState } from "react";
// import logo1 from "../assets/Logo1.png";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider, mtcgmailuid } from "../firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";
// import { getBusinessConfig } from "../firebase/FirestoreFunctions";
// import { useAuthContext } from "./AuthContext";
// import { updateIsGuest } from "../firebase/AuthFunctions";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //   const { setIsGuest } = useAuthContext();

  // useEffect(() => {
  //   if (auth.currentUser?.uid === mtcgmailuid) {
  //     navigate("/dev");
  //   }
  // }, []);

  const handleGoogleSignIn = async () => {
    setLoading(true);

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      if (user !== null) {
        // const businessConfig = await getBusinessConfig(user.uid); // fetch business config from db

        if (user.uid === mtcgmailuid) {
          console.log("YAY!");
          navigate("/dev"); // business config exists for this user so go to dashboard (home)
        } else {
          console.log("BOO!");
          navigate("/"); // go to onboarding
        }
      } else {
        console.log("BOO");
        console.error("Error during sign-in: user is null for some reason");
      }
    } catch (error) {
      console.error("Error during sign-in:", error.message);
    } finally {
      setLoading(false);
    }
  };

  //   const handleContinueAsGuest = () => {
  //     setIsGuest(true); // update state variable
  //     updateIsGuest(true); // update local storage
  //     navigate("/onboarding");
  //   };

  return (
    <div>
      <button
        onClick={handleGoogleSignIn}
        style={{
          padding: 8,
          backgroundColor: "black",
          border: "none",
          color: "white",
          borderRadius: 10,
          marginTop: 10,
        }}
      >
        Admin Login
      </button>
    </div>
  );
};

export default Login;
