// components/Auth/ProtectedRoute.jsx
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../../firebase/FirebaseConfig";

const ProtectedRoute = ({ children, allowedUid }) => {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) return <p>Loading...</p>;

  if (!currentUser || currentUser.uid !== allowedUid) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
