// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDbL2YiSVlHJZr03t7M_xXMZXKxY6hw2bQ",
  authDomain: "mtc-db-firebase.firebaseapp.com",
  projectId: "mtc-db-firebase",
  storageBucket: "mtc-db-firebase.firebasestorage.app",
  messagingSenderId: "340944976821",
  appId: "1:340944976821:web:1d6df1b2a1022ee44c77fc",
  measurementId: "G-4YT5V8LPYS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const mtcgmailuid = "SziU1qCwo7QuMZwXZ3Dczx5T7lt2";
export { app, auth, db, googleProvider, mtcgmailuid };
