import picksData from "../WE/picksData.json";
import {
  TextField,
  Box,
  CircularProgress,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  AlertTitle,
  Alert,
} from "@mui/material";
import { JSX, useState } from "react";
import DataTextField from "./DataTextField";
import { Week, Pick } from "../../firebase/models";
import { FillerPickData } from "./FillerPickData";
import MTC_logo from "../../imgs/MTCLogo/MTC_logo.png";

const BlogEntry = () => {
  const [status, setStatus] = useState("");

  const AOTWINDEX = 0;
  const RUAOTWINDEX = 1;
  const SOTWINDEX = 2;
  const RUSOTWINDEX = 3;
  const PICKIDINDEX = 0;
  const PICKTYPEINDEX = 1;

  const handleBlogEntrySubmissionForm = async (event: any) => {
    console.log("A");
  };

  const [quarterSelect, setQuarterSelect] = useState("[QUARTER]");
  const handleGenericQuarterSelectChange = (event: any) => {
    setQuarterSelect(event.target.value);
  };

  const [yearSelect, setYearSelect] = useState("[YEAR]");
  const handleGenericYearSelectChange = (event: any) => {
    setYearSelect(event.target.value);
  };

  const [blogTypeSelect, setBlogTypeSelect] = useState("[BLOG ENTRY TYPE]");
  const handleBlogTypeSelectChange = (event: any) => {
    setBlogTypeSelect(event.target.value);
  };

  const genericQuarterSelectOptions = ["FQ", "WQ", "SQ"];
  const genericYearSelectOptions = [
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
  ];

  const blogEntryType = [
    "Album Review",
    "Song Review",
    "Email",
    "Media Appearance",
  ];

  return (
    <>
      <div className="faq2">
        <div style={{ textAlign: "center" }}>
          <h1>
            <span style={{ color: "gray" }}>ADD</span> {blogTypeSelect}{" "}
            <span style={{ color: "gray" }}> FOR</span> {quarterSelect}
            {yearSelect}
          </h1>
          <div style={{ marginTop: 15, marginBottom: 20 }}>
            <FormControl style={{ width: 200, marginRight: 15 }}>
              <InputLabel id="demo-simple-select-label">Blog Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={quarterSelect}
                label="Quarter"
                onChange={handleGenericQuarterSelectChange}
              >
                {genericQuarterSelectOptions.map((quarter) => (
                  <MenuItem value={quarter}>{quarter}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: 80, marginRight: 15 }}>
              <InputLabel id="demo-simple-select-label">Quarter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={quarterSelect}
                label="Quarter"
                onChange={handleGenericQuarterSelectChange}
              >
                {genericQuarterSelectOptions.map((quarter) => (
                  <MenuItem value={quarter}>{quarter}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: 80 }}>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={yearSelect}
                label="Quarter"
                onChange={handleGenericYearSelectChange}
              >
                {genericYearSelectOptions.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div
            style={{
              height: 555,
              width: "700px",
              //   border: "solid 1px lightgray",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h3
              style={{
                textAlign: "center",
                marginTop: -10.5,
                marginBottom: 29.5,
              }}
            >
              Album Of The Week
            </h3>
            <div>
              <div
                style={{
                  padding: 5,
                  alignItems: "center",
                  display: "flex",
                  //   justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {/* <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Album Name"
                  setValue={setSongOrAlbumNameAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Artist Name"
                  setValue={setArtistNameAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="Member Name"
                  setValue={setMemberNameAotW}
                />
                <DataTextField
                  required={false}
                  errorMsg={""}
                  label="SpotifyAPI Art Link"
                  setValue={setSongOrAlbumArtAotW}
                /> */}

                <form onSubmit={handleBlogEntrySubmissionForm}>
                  <>
                    <Button
                      onClick={() => console.log("TEST")}
                      variant="outlined"
                      style={{ marginBottom: "-20px", marginTop: "-20px" }}
                    >
                      ADD AOTW
                    </Button>
                  </>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogEntry;
