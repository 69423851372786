import picksData from "../WE/picksData.json";

const lastWeeksRUSotWPickId = Math.max(
  ...picksData.flatMap((quarter: any) =>
    quarter.weeks.flatMap((week: any) =>
      week.picks.map((pick: any) => pick.pickId)
    )
  )
);
const nextWeeksAotW = [lastWeeksRUSotWPickId + 1, "Album of the Week"];
const nextWeeksRUAotW = [
  lastWeeksRUSotWPickId + 2,
  "Runner Up Album of the Week",
];
const nextWeeksSotW = [lastWeeksRUSotWPickId + 3, "Song of the Week"];
const nextWeeksRUSotW = [
  lastWeeksRUSotWPickId + 4,
  "Runner Up Song of the Week",
];
export const FillerPickData = [
  nextWeeksAotW,
  nextWeeksRUAotW,
  nextWeeksSotW,
  nextWeeksRUSotW,
];
